define("discourse/plugins/discourse-landing-pages/discourse/routes/inline-page", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page"], function (_exports, _discourse, _landingPage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model(params) {
      return _landingPage.default.all().then(result => result.pages.find(page => page.path == params.path)).then(page => _landingPage.default.find(page.id)).catch(error => window.location.replace(params.path));
    }
  });
});