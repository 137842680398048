define("discourse/plugins/discourse-landing-pages/discourse/landing-pages-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("inline-page", {
      path: ":path"
    });
  }
});