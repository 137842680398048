define("discourse/plugins/discourse-landing-pages/discourse/templates/inline-page", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{{@model.page.body}}}
  
  */
  {
    "id": "c5Wn4wXX",
    "block": "[[[2,[30,1,[\"page\",\"body\"]]],[1,\"\\n\"]],[\"@model\"],false,[]]",
    "moduleName": "discourse/plugins/discourse-landing-pages/discourse/templates/inline-page.hbs",
    "isStrictMode": false
  });
});