define("discourse/plugins/discourse-landing-pages/discourse/controllers/admin-plugins-landing-pages", ["exports", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page", "discourse/plugins/discourse-landing-pages/discourse/components/modal/import-pages", "discourse/plugins/discourse-landing-pages/discourse/components/modal/update-pages-remote", "@ember/controller", "@ember/service", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/lib/ajax-error", "discourse/lib/ajax", "I18n"], function (_exports, _landingPage, _importPages, _updatePagesRemote, _controller, _service, _decorators, _computed, _ajaxError, _ajax, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const statusIcons = {
    error: "exclamation-triangle",
    success: "check"
  };
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("staticMessage", "resultMessages"), _dec2 = (0, _decorators.default)("pagesNotFetched", "hasCommitsBehind", "fetchingCommits", "page", "remote", "showGlobal"), _dec3 = (0, _decorators.default)("showGlobal"), (_obj = {
    modal: (0, _service.inject)(),
    remoteDisconnected: (0, _computed.not)("remote.connected"),
    pullDisabled: (0, _computed.or)("pullingFromRemote", "remoteDisconnected"),
    fetchingCommits: false,
    commitsBehind: null,
    hasCommitsBehind: (0, _computed.gt)("commitsBehind", 0),
    hasMessages: (0, _computed.notEmpty)("messages.items"),
    showCommitsBehind: false,
    messages(staticMessage, resultMessages) {
      if (resultMessages) {
        setTimeout(() => {
          this.set("resultMessages", null);
        }, 15000);
        return {
          status: resultMessages.type,
          items: resultMessages.messages.map(message => {
            return {
              icon: statusIcons[resultMessages.type],
              text: message
            };
          })
        };
      } else if (staticMessage) {
        return {
          status: "static",
          items: [{
            icon: staticMessage.icon,
            text: staticMessage.text
          }]
        };
      } else {
        return null;
      }
    },
    staticMessage(pagesNotFetched, hasCommitsBehind, fetchingCommits, page, remote, showGlobal) {
      let key;
      let icon = "info-circle";
      if (page) {
        if (page.remote) {
          key = "page.remote.description";
          icon = "book";
        } else {
          key = "page.local.description";
          icon = "desktop";
        }
      } else if (showGlobal) {
        key = "global.description";
      } else if (remote && remote.connected) {
        if (pagesNotFetched) {
          key = "remote.repository.not_fetched";
        } else if (fetchingCommits) {
          key = "remote.repository.checking_status";
        } else if (hasCommitsBehind) {
          key = "remote.repository.out_of_date";
        } else {
          key = "remote.repository.up_to_date";
        }
      }
      if (key) {
        return {
          icon,
          text: _I18n.default.t(`admin.landing_pages.${key}`)
        };
      } else {
        return null;
      }
    },
    documentationUrl(showGlobal) {
      const rootUrl = "https://thepavilion.io/t";
      return showGlobal ? `${rootUrl}/4098` : `${rootUrl}/4094`;
    },
    actions: {
      changePage(pageId) {
        if (pageId) {
          _landingPage.default.find(pageId).then(result => {
            if (result.page) {
              const page = _landingPage.default.create(result.page);
              this.setProperties({
                page,
                currentPage: JSON.parse(JSON.stringify(page)),
                showGlobal: false
              });
            }
          });
        } else {
          this.setProperties({
            page: null,
            currentPage: null
          });
        }
      },
      createPage() {
        this.set("page", _landingPage.default.create({
          creating: true
        }));
      },
      importPages() {
        this.modal.show(_importPages.default).then(result => {
          if (result?.page) {
            const page = _landingPage.default.create(result.page);
            this.setProperties({
              page: page,
              currentPage: JSON.parse(JSON.stringify(page)),
              pages: result.pages
            });
          }
        });
      },
      updateRemote() {
        this.modal.show(_updatePagesRemote.default, {
          model: {
            remote: this.remote,
            buffered: JSON.parse(JSON.stringify(this.remote))
          }
        }).then(result => {
          if (result?.remote) {
            this.setProperties({
              remote: result.remote,
              pagesNotFetched: true
            });
          }
        });
      },
      pullFromRemote() {
        this.set("pullingFromRemote", true);
        (0, _ajax.ajax)("/landing/remote/pages").then(result => {
          const pages = result.pages;
          const menus = result.menus;
          const global = result.global;
          const report = result.report;
          this.setProperties({
            pages,
            menus,
            global,
            page: null,
            showGlobal: false
          });
          if (report.errors.length) {
            this.set("resultMessages", {
              type: "error",
              messages: result.report.errors
            });
          } else {
            let imported = report.imported;
            let messages = [];
            ["scripts", "menus", "assets", "pages"].forEach(listType => {
              if (imported[listType].length) {
                messages.push(_I18n.default.t(`admin.landing_pages.imported.x_${listType}`, {
                  count: imported[listType].length
                }));
              }
            });
            ["footer", "header"].forEach(boolType => {
              if (imported[boolType]) {
                messages.push(_I18n.default.t(`admin.landing_pages.imported.${boolType}`));
              }
            });
            this.set("resultMessages", {
              type: "success",
              messages
            });
            this.send("commitsBehind");
          }
        }).catch(error => {
          this.set("resultMessages", {
            type: "error",
            messages: [(0, _ajaxError.extractError)(error)]
          });
        }).finally(() => {
          this.set("pullingFromRemote", false);
        });
      },
      commitsBehind() {
        this.set("fetchingCommits", true);
        (0, _ajax.ajax)("/landing/remote/commits-behind").then(result => {
          if (!result.failed) {
            this.set("commitsBehind", result.commits_behind);
          }
        }).finally(() => {
          this.set("fetchingCommits", false);
        });
      },
      updatePages(pages) {
        this.set("pages", pages);
      },
      toggleShowGlobal() {
        this.setProperties({
          showGlobal: true,
          page: null,
          currentPage: null
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "messages", [_dec], Object.getOwnPropertyDescriptor(_obj, "messages"), _obj), _applyDecoratedDescriptor(_obj, "staticMessage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "staticMessage"), _obj), _applyDecoratedDescriptor(_obj, "documentationUrl", [_dec3], Object.getOwnPropertyDescriptor(_obj, "documentationUrl"), _obj)), _obj)));
});