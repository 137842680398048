define("discourse/plugins/discourse-landing-pages/discourse/models/landing-page", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/computed", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _computed, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const basePath = "/landing/page";
  const LandingPage = _object.default.extend({
    exportUrl: (0, _computed.url)("id", `${basePath}/%@/export`),
    savePage() {
      const creating = this.creating;
      let path = basePath;
      if (!creating) {
        path += `/${this.id}`;
      }
      let page = {
        name: this.name,
        path: this.path,
        parent_id: this.parent_id,
        category_id: this.category_id,
        theme_id: this.theme_id,
        group_ids: this.group_ids,
        body: this.body,
        inline: this.inline,
        menu: this.menu
      };
      return (0, _ajax.ajax)(path, {
        type: creating ? "POST" : "PUT",
        contentType: "application/json; charset=UTF-8",
        data: JSON.stringify(page)
      });
    },
    destroyPage() {
      return (0, _ajax.ajax)(`${basePath}/${this.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    },
    exportPage() {
      return (0, _ajax.ajax)(this.exportUrl, {
        type: "GET",
        dataType: "binary",
        xhrFields: {
          responseType: "blob"
        }
      });
    }
  });
  LandingPage.reopenClass({
    all() {
      return (0, _ajax.ajax)(basePath).catch(_ajaxError.popupAjaxError);
    },
    find(pageId) {
      return (0, _ajax.ajax)(`${basePath}/${pageId}`).catch(_ajaxError.popupAjaxError);
    },
    create() {
      let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const page = this._super.apply(this);
      page.setProperties(props);
      return page;
    },
    import(data) {
      return (0, _ajax.ajax)(`${basePath}/upload`, {
        type: "POST",
        processData: false,
        contentType: false,
        data
      });
    }
  });
  var _default = _exports.default = LandingPage;
});