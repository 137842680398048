define("discourse/plugins/discourse-landing-pages/discourse/components/global-admin", ["exports", "@ember/component", "@ember/object/computed", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page-global"], function (_exports, _component, _computed, _landingPageGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: "global-admin",
    updatingGlobal: (0, _computed.or)("destroyingGlobal", "savingGlobal"),
    didReceiveAttrs() {
      this.initializeProps();
    },
    initializeProps() {
      this.setProperties({
        scripts: this.global?.scripts,
        jsonHeader: JSON.stringify(this.global?.header || undefined, undefined, 4),
        jsonFooter: JSON.stringify(this.global?.footer || undefined, undefined, 4)
      });
    },
    actions: {
      saveGlobal() {
        this.setProperties({
          savingGlobal: true,
          jsonHeaderError: null,
          jsonFooterError: null
        });
        let self = this;
        let scripts = this.scripts;
        let header;
        let footer;
        try {
          header = this.jsonHeader ? JSON.parse(this.jsonHeader) : null;
        } catch (e) {
          this.setProperties({
            jsonHeaderError: e.message,
            savingGlobal: false
          });
        }
        try {
          footer = this.jsonFooter ? JSON.parse(this.jsonFooter) : null;
        } catch (e) {
          this.setProperties({
            jsonFooterError: e.message,
            savingGlobal: false
          });
        }
        if (this.jsonHeaderError || this.jsonFooterError) {
          self.set("resultIcon", "times");
          setTimeout(() => self.set("resultIcon", null), 10000);
          return;
        }
        const global = {
          scripts,
          header,
          footer
        };
        const data = {
          global
        };
        _landingPageGlobal.default.save(data).then(result => {
          if (result.success) {
            self.setProperties({
              resultIcon: "check",
              global
            });
            self.initializeProps();
          } else {
            self.set("resultIcon", "times");
          }
          setTimeout(() => {
            self.set("resultIcon", null);
          }, 10000);
        }).finally(() => {
          self.set("savingGlobal", false);
        });
      },
      destroyGlobal() {
        this.set("destroyingGlobal", true);
        let self = this;
        _landingPageGlobal.default.destroy().then(result => {
          if (result.success) {
            self.setProperties({
              resultIcon: "check",
              global: null,
              scripts: null,
              headerJSON: null,
              footerJSON: null
            });
          } else {
            self.set("resultIcon", "times");
          }
          setTimeout(() => {
            self.set("resultIcon", null);
          }, 10000);
        }).finally(() => {
          self.set("destroyingGlobal", false);
        });
      }
    }
  });
});